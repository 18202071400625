<template>
  <div>
    <EmployeeSelector :single="true" @change="onChange" :disabled="disabled">
      <a-button block style="text-align: left">
        <div v-if="item.userName">
          {{ item.userName }}
        </div>
        <div v-else class="input-style"><span>请输入部门/姓名/拼音/联系方式</span><a-icon type="search"/></div>
      </a-button>
    </EmployeeSelector>
  </div>
</template>
<script>
import EmployeeSelector from "@/components/employee-selector";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    EmployeeSelector,
  },

  methods: {
    onChange(value) {
      if (value.length > 0) {
        this.$emit("change", value[0]);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ant-btn .input-style{
    color:#ccc;
    display:flex;
    justify-content: space-between;
    .anticon-search{
      color:#4190f7;font-size:16px;
    }
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
}

</style>