<template>
  <div class="project">
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs v-model="activeKey" :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0" :tab="title"></a-tab-pane>
        <a-tab-pane key="1" tab="人员分配"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button" @click="exportForm">
            <img class="export" :src="exportImage" alt />
            <span>导出文档</span>
          </div>
          <div class="button" @click="download">
            <img class="download" :src="downloadImage" alt />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <div v-show="activeKey === '0'">
        <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="当前项目" class="default-highlight">
                <a-input :disabled="true" v-decorator="['designCode']"></a-input>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="管理类别">
                <a-radio-group :disabled="true" v-decorator="['type']" class="default-highlight">
                  <a-radio
                    v-for="item in epcTypeList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item
                label="工程名称"
                class="default-highlight"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 18 }"
              >
                <a-input :disabled="true" v-decorator="['name']" />
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item
                label="情况说明"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 18 }"
                class="default-highlight"
              >
                <a-textarea :disabled="true" :auto-size="{ minRows: 5 }" v-decorator="['remark']" />
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <span slot="label" class>附件</span>
                <div class="link-list" v-decorator="['file']">
                  <a-checkbox-group :disabled="true" :value="selectedFileList">
                    <div class="link" v-for="item in fileList" :key="item.id">
                      <a-checkbox :value="item.id">
                        <a target="_blank" download :href="item.completePath">
                          {{
                          item.name
                          }}
                        </a>
                      </a-checkbox>
                    </div>
                  </a-checkbox-group>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <div class="center">
            <a-space>
              <a-button @click="$close($route.path)">关闭</a-button>
              <a-button @click="activeKey = '1'" type="primary">下一步</a-button>
              <a-button type="primary" @click="$router.push('/epc/project/update?id=' + id)">编辑</a-button>
            </a-space>
          </div>
        </a-form>
      </div>

      <div v-show="activeKey === '1'">
        <div class="grid-container">
          <div
            class="card"
            v-for="(item, index) in memberList"
            :key="index"
            :class="item.tempList.length > 1 ? 'full' : '' "
          >
            <a-row
              v-if="item.tempList.length > 1"
              style="margin: 0 10px; background-color: #f8f8f8;padding-bottom: 8px"
            >
              <a-button type="primary">{{ item.deptName }}</a-button>
              <div v-if="Array.isArray(item.tempList)">
                <a-col
                  :lg="8"
                  :md="8"
                  :sm="24"
                  v-for="element in item.tempList"
                  :key="element.role"
                >
                  <template>
                    <a-form-item>
                      <div class="member-form">
                        <DataDictFinder
                          class="left"
                          dictType="epc_prj_member_role"
                          :dictValue="element.role"
                        />
                        <MemberSelecor :disabled="true" class="member-right" :item="element"></MemberSelecor>
                      </div>
                    </a-form-item>
                  </template>
                </a-col>
              </div>
            </a-row>
            <div v-else>
              <div
                v-for="element in item.tempList"
                :key="element.role"
                style="
                    background-color: #f8f8f8;
                    padding-bottom: 8px;
                    border: 10px solid #fff;
                  "
              >
                <a-button type="primary">{{ item.deptName }}</a-button>
                <a-form-item>
                  <div class="member-form">
                    <DataDictFinder
                      class="left"
                      dictType="epc_prj_member_role"
                      :dictValue="element.role"
                    />
                    <MemberSelecor :disabled="true" class="member-right" :item="element"></MemberSelecor>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
        </div>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button @click="activeKey = '0'">上一步</a-button>
            <a-button
              type="primary"
              @click="
                $router.push('/epc/project/update?id=' + id + '&activeKey=1')
              "
            >编辑</a-button>
          </a-space>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchDetail,
  fetchPrjMemberList,
  exportFile,
  downloadTemplate
} from "@/api/epc";
import { mapGetters } from "vuex";
import MemberSelecor from "./component/member-selector.vue";
import { saveAs } from "file-saver";

export default {
  components: {
    MemberSelecor
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      activeKey: "0",
      title: "组织机构建立",

      form: this.$form.createForm(this),
      id: "",
      projectName: "",

      fileList: [],
      selectedFileList: [],

      memberList: [] // 部门下角色列表
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    epcTypeList() {
      return this.findDataDict("epc_prj_type");
    }
  },
  mounted() {
    const { query } = this.$route;
    // 从项目阶段进入。pid即为id
    const { id, pid } = query || {};

    if (!id && !pid) {
      return;
    }
    this.id = id || pid || "";

    this.getDetail();
    this.getMemberList();
  },
  methods: {
    exportForm() {
      exportFile({ pid: this.id })
        .then(blob => {
          saveAs(blob, `${this.projectName}_${this.title}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then(blob => {
          saveAs(blob, `${this.title}模版.docx`);
        })
        .catch();
    },

    getDetail() {
      fetchDetail(this.id).then(res => {
        if (res) {
          this.projectName = res.name;
          this.form.setFieldsValue({
            designCode: res.designCode,
            name: res.name,
            remark: res.remark,
            type: res.type,
            file: res.attachmentList
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        }
      });
    },
    getMemberList() {
      fetchPrjMemberList({ pageNum: 1, pageSize: 1000, pid: this.id })
        .then(res => {
          if (Array.isArray(res.list)) {
            const deptList = [...new Set(res.list.map(item => item.deptName))];
            this.memberList = deptList.map(item => {
              const arr = [];
              res.list.forEach(element => {
                if (element.deptName === item) {
                  arr.push({
                    ...element
                  });
                }
              });
              return {
                deptName: item,
                tempList: arr
              };
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
  .card {
    margin-bottom: 20px;
    .ant-btn {
      margin-bottom: 15px;
      width: 130px;
    }
    .member-form {
      display: flex;
      padding: 0 10px;
      .member-right {
        margin-left: 10px;
        width: 65%;
      }
    }
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .full {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.center {
  padding: 80px 0;
}
</style>
<style lang="less">
.project {
  .left span {
    width: 60px !important;
  }
}
</style>
